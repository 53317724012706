import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import AboutSubmenu from "../../components/template-partials/about/about-submenu"
//import SurvivalGraph from "../../components/template-partials/about/survival-graph"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import { UpNextCard } from "../../components/template-partials/global-components/footer-cards"

import voluntaryClinical from "../../images/1.3_voluntary_clinical.png"
import clinicalPhases from "../../images/1.3_phasesclinicaltrial.png"
import hospital from "../../images/1.3.03_hospital.png"
import headingBg from "../../images/1.0_backgroundheader.jpg"
import headingRound from "../../images/about-and-research-nav-image.jpg"
import clinicalTrialChart from "../../images/new-chart@2x.jpg"

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import VideoPlayer from "../../components/VideoPlayer"

const TreatmentAndTrialsPage = () => {
  return (
    <Layout>
      <SEO
        title="Neuroblastoma Treatment and Clinical Trials"
        description="Clinical trials play an important role in the advancement of neuroblastoma treatments, and your child may be eligible to enroll as part of their therapy. Learn about the possible benefits and drawbacks."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Neuroblastoma Treatment and Clinical Trials"
      >
        <h1 className="section__heading section__heading--green h1">
          Neuroblastoma Treatment <em>and</em> Clinical Trials
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`About Neuroblastoma &amp; Research`}>
              <AboutSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">
                  Neuroblastoma treatment advancements through clinical trials{" "}
                </h2>
                <p>
                  Over the years, many research studies (also known as{" "}
                  <Link
                    rel="glossary"
                    to="/support-and-community/glossary/#clinical-trials"
                  >
                    clinical trials
                  </Link>
                  ) have been conducted to understand the best ways to treat
                  neuroblastoma. From these clinical trials, oncologists have
                  established current standards for neuroblastoma treatment.{" "}
                  <Link to="/diagnosis/understanding-treatment/">
                    Find more information on the current neuroblastoma treatment
                    options.
                  </Link>
                </p>
                <p>
                  Clinical trials may provide your child the opportunity to
                  receive cutting-edge treatment. Children with neuroblastoma
                  are living longer today because of past clinical trials that
                  showed new treatments were safe and effective.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Evolution through Clinical Trials
            </h3>
            <p>
              Learn why clinical trials are important in neuroblastoma and why
              they may be an option for your child.
            </p>
            <VideoPlayer
              embedId="761928292"
              title="Evolution through Clinical Trials"
            />
          </div>
        </div>
      </Section>

      <Section className="section--no-pad">
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Evolution of high-risk neuroblastoma treatment
            </h3>
            <p>
              For example, in patients with high-risk neuroblastoma, the
              survival at diagnosis is reported to be about 50%.<sup>1</sup>{" "}
              Clinical trials have shown that adding additional agents to the
              standard therapy improves survival in patients with
              treatment-responsive disease. The figure below highlights the
              evolution of high-risk neuroblastoma treatment through various
              studies. Of note, these studies represent specific patient
              populations, and these results cannot be extrapolated as a whole.
              It’s important to discuss with your child’s doctor what their
              specific prognosis and treatment will be.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle">
          <div className="columns">
            <p class="lg_mt-2 mt-2 lg_mb-2 mb-2 overflow-graph">
              <img
                src={clinicalTrialChart}
                alt="Evolution of high-risk neuroblastoma treatment"
                style={{ minWidth: `800px` }}
              />
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle">
          <div className="columns">
            <p>
              To find out more about the clinical trials that inform the figure
              above, you can read the articles using the links below.
            </p>
            <ul className="ul--standard">
              <li>
                <a
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="DuBois Journal of Clinical Oncology"
                  href="https://ascopubs.org/doi/10.1200/JCO.21.01066?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr_dat=cr_pub++0pubmed%3E&"
                  target="_blank"
                >
                  Read the article by Dr DuBois published in the Journal of
                  Clinical Oncology.{" "}
                  <small>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </small>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Matthay New England Journal of Medicine"
                  href="https://www.nejm.org/doi/10.1056/NEJM199910143411601?url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org&rfr_dat=cr_pub++0www.ncbi.nlm.nih.gov%3E"
                  target="_blank"
                >
                  Read the article by Dr Matthay published in the New England
                  Journal of Medicine.{" "}
                  <small>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </small>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Park Journal of the American Medical Association"
                  href="https://jamanetwork.com/journals/jama/fullarticle/2748795"
                  target="_blank"
                >
                  Read the article by Dr Park published in the Journal of the
                  American Medical Association.{" "}
                  <small>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </small>
                </a>
              </li>
            </ul>
            <p class="lg_mt-3 mt-2 small">
              <small>
                <strong>References: 1.</strong> DuBois SG, Bagatell R. Improving
                outcomes in children with high-risk neuroblastoma: the role of
                randomized trials. <em>J Clin Oncol.</em> 2021.
                doi:10.1200/JCO.21.01066. <strong>2.</strong> Matthay KK,
                Villablanca JG, Seeger RC, et al; for the Children's Cancer
                Group. Treatment of high-risk neuroblastoma with intensive
                chemotherapy, radiotherapy, autologous bone marrow
                transplantation, and 13-<em>cis</em>-retinoic acid.{" "}
                <em>N Engl J Med.</em> 1999;341(16):1165-1173.{" "}
                <strong>3.</strong> Park JR, Kreissman SG, London WB, et al.
                Effect of tandem autologous stem cell transplant vs single
                transplant on event-free survival in patients with high-risk
                neuroblastoma: a randomized clinical trial. <em>JAMA.</em>{" "}
                2019;322(8):746-755.
              </small>
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Introduction to clinical trials
            </h3>
            <p>
              <Link
                rel="glossary"
                to="/support-and-community/glossary/#clinical-trials"
              >
                Clinical trials
              </Link>{" "}
              are research studies that test new ways to improve treatments in a
              group of people with a certain disease. In neuroblastoma, clinical
              trials often focus on new treatments to see if they are safer or
              more effective than what is currently available.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 lg_mb-2">
          <div className="columns small-12 large-shrink text-center large-text-left">
            <img
              src={voluntaryClinical}
              alt="Clinical trials are research studies that test new ways to improve treatments in a group of people with a certain disease"
            />
          </div>
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              Clinical trials follow strict standards and are completely
              voluntary{" "}
            </h4>
            <ul className="ul--dotted">
              <li>
                Clinical trials follow strict rules set by the Food and Drug
                Administration (FDA). These rules make sure that clinical trials
                are as safe as possible
              </li>
              <li>
                Participation in clinical trials is completely voluntary and you
                may choose to withdraw your child from a clinical trial at any
                time if you change your mind
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--inner lg_mt-3">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              Clinical trials are broken out by phases
            </h4>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 lg_mb-3 mt-1 mb-2">
          <div className="columns text-center">
            <img
              src={clinicalPhases}
              alt="Neuroblastoma clinical trials happen in phases. Goals for each trial depend on its phase and your child’s diagnosis."
            />
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <p>
              The goal for a specific clinical trial will depend on the{" "}
              <Link
                rel="glossary"
                to="/support-and-community/glossary/#phase-in-a-clinical-trial"
              >
                phase
              </Link>{" "}
              it's in.
            </p>
            <ul className="ul--dotted">
              <li>
                Phase 1: The purpose of a phase 1 clinical trial is to find a
                safe dose for the treatment being tested
              </li>
              <li>
                Phase 2: The purpose of a phase 2 clinical trial is to see how
                the treatment affects the body and fights cancer
              </li>
              <li>
                Phase 3: A phase 3 clinical trial generally tests the standard
                treatment against a new alternative treatment. The goal for this
                type of trial is to see if the new alternative treatment helps
                to increase cure rates, decrease side effects, or limit{" "}
                <Link
                  rel="glossary"
                  to="/support-and-community/glossary/#late-effect"
                >
                  late effects
                </Link>{" "}
                of treatment
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 lg_mb-2">
          <div className="columns small-12 large-4 text-center large-text-left">
            <img
              src={hospital}
              alt="Clinical trials follow strict standards and are completely voluntary"
            />
          </div>
          <div className="columns mt-2">
            <h4 className="h4 tc-viridian jost-med">
              Clinical trials may vary based on location
            </h4>
            <p>
              Different hospitals are associated with different research
              organizations, so some clinical trials may only be available at
              certain hospitals.{" "}
              <Link to="/what-is-neuroblastoma/research/">
                Learn more about the different research groups in neuroblastoma.
              </Link>
            </p>
          </div>
        </div>

        <InlineInfocard
          className="lg_mt-2 mt-2"
          text="Clinical trials may provide your child the opportunity to receive cutting-edge treatment. Children with neuroblastoma are living longer today because of past clinical trials that showed new treatments were safe and effective."
        />

        <div className="row row--inner align-middle lg_mt-2 lg_mb-2 mt-2">
          <div className="columns">
            <p>
              <strong>
                <a
                  data-gtm-event-category="External Link"
                  data-gtm-event-action="Click"
                  data-gtm-event-label="Find Detailed Info"
                  href="https://childrensoncologygroup.org/index.php/what-is-a-clinical-trial"
                  target="_blank"
                  rel="noreferrer"
                >
                  Find detailed information on the different phases of a
                  clinical trial.{" "}
                  <small>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </small>
                </a>
              </strong>
            </p>
            <p>
              <strong>
                The decision to explore clinical trials or choose the current
                standard treatment is one that should be made along with your
                child’s oncologist. Because every neuroblastoma diagnosis is
                unique, your child’s oncologist can help you find the treatment
                best tailored to meet the needs of your child.
              </strong>
            </p>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard
          topic="Understanding Clinical Trials"
          title="Want to know more about clinical trials?"
          lead="Learn how to interpret clinical trials and their results."
          url="/what-is-neuroblastoma/clinical-trial-results/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default TreatmentAndTrialsPage
